import React from "react";

import img1 from "@/assets/landing/1.png";
import img2 from "@/assets/landing/2.png";
import img3 from "@/assets/landing/3.png";
import img4 from "@/assets/landing/4.png";
import img5 from "@/assets/landing/5.png";
import img6 from "@/assets/landing/6.png";

const BlockCard = ({
  img,
  title,
  content,
}: {
  img: string;
  title: string;
  content: string;
}) => {
  return (
    <div className="col-12 md:col-4 mb-4 px-5 flex flex-column align-items-center">
      <div className="mb-3 h-15rem flex align-items-center">
        <img src={img} alt={title} className="w-full" />
      </div>
      <div>
        <div className="text-900 text-2xl mb-3 text-primary">{title}</div>
        <p className="text-base text-primary text-left text-700 line-height-3">
          {content}
        </p>
      </div>
    </div>
  );
};

const AboutPortfolii = () => {
  return (
    <div className="surface-section px-4 py-6 md:px-6 lg:px-5 text-center">
      <div className="mb-3 font-bold text-3xl">
        <p className="text-primary font-normal text-5xl">
          What makes <span style={{ color: "#F59E0B" }}>portfolii</span>{" "}
          different?
        </p>
      </div>

      <div className="grid">
        <BlockCard
          img={img1.src}
          title="No Resume, No hassle!"
          content="portfolii, gives you a simpler, yet more effective way to apply for jobs. Showcase your skills and achievement through personalized portfolios and stand out from the crowd with dynamic, story-driven applications that make a lasting impression on employers."
        />
        <BlockCard
          img={img2.src}
          title="Custom Applications In Minutes"
          content="Say goodbye to the resume customization hassle. With intuitive platform, tailor each job application effortlessly. No more juggling multiple resumes or losing track of versions.  portfolii streamlines customization, helping you stand out with ease and precision."
        />
        <BlockCard
          img={img3.src}
          title="Powerful Storytelling with AI"
          content="Experience the 'Click' – when your career stories perfectly align with key job requirements. Leverage AI to tell your achievement effectively, convincing the hiring teams you're the right fit for the job."
        />
        <BlockCard
          img={img4.src}
          title="Your Personal Story Vault"
          content="Unlock the power of your unique experiences. portfolii serves as
            your personal story vault, ensuring you never overlook an
            achievement, no matter how small. Save and access your stories
            effortlessly for custom applications that capture your journey."
        />
        <BlockCard
          img={img5.src}
          title="Express Yourself Authentically"
          content="Your resume doesn't define who you are. portfolii lets you break
            free from the confines of traditional resumes and infuse your
            applications with your true personality and authenticity. Show
            employers the real 'you' beyond the paper."
        />
        <BlockCard
          img={img6.src}
          title="Secure Portfolio Sharing, Your Way"
          content="Networking made simple and secure. portfolii offers you the power to share your portfolio confidently during networking events or job fairs, knowing you have full control over who accesses your information and for how long."
        />
      </div>
    </div>
  );
};

export default AboutPortfolii;
