import { useUser } from "@/context/AuthContext";
import Link from "next/link";
import React from "react";

const RecruiterBanner = () => {
  const { isLogin, currentUser } = useUser();

  return (
    <div className="surface-section pb-5 ">
      <div
        className="px-3 py-4 lg:px-8 lg:py-6 flex flex-column md:flex-row align-items-center justify-content-between"
        style={{
          borderRadius: "1rem",
          background:
            "linear-gradient(220deg, rgba(77, 44, 91, 0.91) 33.88%, rgba(77, 44, 91, 0.91) 81.69%, rgba(245, 158, 11, 0.91) 81.69%)",
        }}
      >
        <div className="pr-6">
          <div className="text-white font-medium text-3xl mb-3">
            Are You A Recruiter Looking For High-Achieving Talents?
          </div>
          <div className="text-white font-medium text-lg">
            Sign up to our waiting list for early access and more exclusive
            offers!
          </div>
        </div>
        <div className="mt-4 mr-auto md:mt-0 md:mr-0">
          <Link
            href={"/auth/register?usertype=recruiter"}
            className="p-button text-2xl border-round-2xl no-underline p-3"
            style={{ backgroundColor: "#f7a733" }}
          >
            GET AN EARLY INVITATION
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RecruiterBanner;
