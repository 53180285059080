import React from "react";

const FAQBlock = ({
  question,
  answer,
}: {
  question: string;
  answer: string;
}) => {
  return (
    <div className="col-12 md:col-6 lg:col-4 p-2 mb-5">
      <div className=" text-xl text-900 mb-3 text-primary">{question}</div>
      <p className="line-height-3 text-primary">{answer}</p>
    </div>
  );
};
const FAQ = () => {
  return (
    <div className="surface-section px-4 py-6 md:px-6">
      <p className="text-primary font-normal text-5xl text-center mb-6">
        Frequently Asked Questions
      </p>
      <div className="grid grid-nogutter">
        <FAQBlock
          question="1. What is portfolii?"
          answer="portfolii is a cutting-edge AI-based job application platform that empowers individuals to craft personalized portfolios, narrate compelling career stories, and seamlessly connect with recruiters."
        />
        <FAQBlock
          question="2. How is portfolii different from a traditional resume?"
          answer="Unlike traditional resumes, portfolii places a strong emphasis on storytelling and personality. It enables users to build dynamic portfolios that transcend the limitations of a traditional resume, showcasing not just skills and achievements but the unique personality of the individual."
        />
        <FAQBlock
          question="3. Is there a cost to use portfolii?"
          answer="No, portfolii is completely free for talents. Enjoy all the features and benefits it offers without any charges, making it an accessible and valuable tool for your job application journey."
        />

        <FAQBlock
          question="4. How does portfolii differ from a static online profile?"
          answer="While static online profiles offer a basic overview, portfolii provides unparalleled flexibility and customization. With portfolii, users can create dynamic portfolios that evolve with their career, offering a more comprehensive and engaging representation compared to static profiles."
        />
        <FAQBlock
          question="5. How do I create my portfolio?"
          answer="Creating your portfolio is a breeze. Simply sign up, upload your resume as text, and watch AI magic generate your portfolio within minutes. Prefer a hands-on approach? Start from scratch, personalize with details, and showcase your skills. Your unique career story is just a few clicks away from expert presentation."
        />
        <FAQBlock
          question="6. Can I have more than one portfolio?"
          answer="Certainly. Recognizing that one size does not fit all in job applications, portfolii allows you to create multiple custom portfolios tailored for different job applications. Each portfolio can highlight specific skills and achievements relevant to the target job."
        />
        <FAQBlock
          question="7. Can I share my portfolios with recruiters and employers?"
          answer="Absolutely. Sharing your portfolio is effortless – select the desired portfolio, generate an access link, and share it with whomever you choose. Maintain control over who views your portfolio by adding a password or setting a limited access timeframe."
        />
        <FAQBlock
          question="8. How does portfolii help me with storytelling?"
          answer="portfolii leverages state-of-the-art AI technology to assist you in crafting compelling achievement stories. It helps you engage employers with narratives that effectively highlight your accomplishments, making your portfolio stand out."
        />
        <FAQBlock
          question="9. Is my information secure on portfolii?"
          answer="Yes, your information is secure. portfolii prioritizes data privacy and implements robust security measures to safeguard your portfolio and personal information."
        />
        <FAQBlock
          question="10. Can I use portfolii on mobile devices?"
          answer="Yes, portfolii is designed to be mobile-friendly, allowing you to access and update your portfolio on the go, providing convenience and flexibility."
        />
      </div>
    </div>
  );
};

export default FAQ;
