import React from "react";

import img from "@/assets/landing/how-it-works.png";

const BlockItem = ({
  title,
  description,
  showLine = true,
}: {
  showLine?: boolean;
  title: string;
  description: string;
}) => {
  return (
    <div className="mb-5 flex align-items-end">
      <div
        className="flex flex-column align-items-center"
        style={{ width: "2rem" }}
      >
        {showLine && (
          <div
            className="h-full bg-primary mr-4"
            style={{
              width: "2px",
              minHeight: "3rem",
              marginBottom: "-1rem",
            }}
          ></div>
        )}

        <span
          className="bg-primary flex align-items-center justify-content-center border-circle"
          style={{
            minWidth: "3.5rem",
            minHeight: "3.5rem",
            zIndex: "2",
          }}
        ></span>
        <span
          className="flex align-items-center justify-content-center border-circle"
          style={{
            minWidth: "3.5rem",
            minHeight: "3.5rem",
            background: "#C8F6DF",
            marginRight: "1rem",
            marginTop: "-2rem",
          }}
        ></span>
      </div>
      <div className="ml-7">
        <div className="text-2xl mb-2 text-primary">{title}</div>
        <span className="line-height-3 text-primary">{description}</span>
      </div>
    </div>
  );
};

const HowItWorks = () => {
  return (
    <div className="surface-section px-2 md:px-4 lg:px-6">
      <p className="text-primary font-normal text-5xl text-center mb-2 mt-6">
        How It Works
      </p>
      <div className="flex align-items-center surface-section px-4 py-8 lg:px-0">
        <div className="grid">
          <div className="col-12 md:col-12 lg:px-5">
            <div className="pr-0 md:pr-8">
              <BlockItem
                showLine={false}
                title="Create Your main portfolio"
                description="Getting started is easy! Upload your resume and let AI build your unique portfolio for you in just a few minutes. Review and add more personality to it for example by adding as intro video. Showcase your professional journey with personalized details that highlight your strengths. Your portfolio is your canvas; make it reflect your career story."
              />
              <BlockItem
                title="Craft Compelling Achievement Stories"
                description="Unlock the art of storytelling using AI technology. We'll walk you through creating compelling narratives about your achievements. Show, don't just tell, how you've excelled in your career. Engage employers with stories that captivate and leave a lasting impact."
              />
              <BlockItem
                title="Seize the Opportunity and Share with Control"
                description="Now it's time to shine. Sit back and wait for recruiters to
                invite you to apply for their jobs or share your portfolios
                securely with the recruiters or hiring managers during job
                fairs or networking events. You're in control! Each
                application can feature a custom portfolio, giving you the
                flexibility to tailor your pitch for the perfect fit."
              />
            </div>
          </div>
        </div>
        <div className="w-10 hidden lg:block">
          <img src={img.src} alt="Image" className="w-full" />
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
