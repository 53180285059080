import { useUser } from "@/context/AuthContext";
import Link from "next/link";
import React from "react";

const Banner = () => {
  const { isLogin, currentUser } = useUser();

  return (
    <div className="surface-section pb-5 ">
      <div
        className="px-3 py-4 lg:px-8 lg:py-6 flex flex-column md:flex-row align-items-center justify-content-between"
        style={{
          borderRadius: "1rem",
          background: "rgba(195, 245, 220, 0.91)",
        }}
      >
        <div className="pr-6">
          <div className="text-primary font-medium text-4xl mb-2">
            Start Your Next Big Move Now
          </div>
          <div className="text-primary font-medium text-md">
            TAKE THE NEXT STEP IN BUILDING YOUR CAREER
          </div>
        </div>
        <div className="mt-4 mr-auto md:mt-0 md:mr-0">
          <Link
            href={
              isLogin ? `/dashboard/${currentUser?.userType}` : "/auth/register"
            }
            className="p-button text-2xl border-round-2xl no-underline p-3 hover:bg-primary-800"
          >
            Get Started
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Banner;
