import Hero from "@/components/Landing/Hero";
import RecruiterBanner from "@/components/Landing/RecruiterBanner";
import AboutPortfolii from "@/components/Landing/AboutPortfolii";
import HowItWorks from "@/components/Landing/HowItWorks";
import Banner from "@/components/Landing/Banner";

import FAQ from "@/components/Landing/FAQ";
import Footer from "@/components/Landing/Footer";

export default function Home() {
  return (
    <div className="bg-white">
      <Hero />
      <AboutPortfolii />
      <div className="mx-3 lg:mx-6">
        <RecruiterBanner />
      </div>
      <HowItWorks />

      <FAQ />
      <div className="mx-3 lg:mx-6">
        <Banner />
      </div>
      <Footer />
    </div>
  );
}
