import { useUser } from "@/context/AuthContext";
import Link from "next/link";
import React from "react";

const Hero = () => {
  const { isLogin, currentUser } = useUser();

  return (
    <div className="relative lg:px-6 overflow-hidden hero">
      <div className="text-center lg:text-right py-5 lg:py-8 relative">
        <h1 className="text-7xl text-white font-bold lg:pt-8 line-height-1">
          Build Your Career
          <br />
          <span className="text-4xl font-bold">One Story At A Time!</span>
        </h1>
        <Link
          href={
            isLogin ? `/dashboard/${currentUser?.userType}` : "/auth/register"
          }
          className="text-center font-medium no-underline bg-primary py-3 px-1 md:px-3 md:p-3 border-round-sm border-1 border-transparent border-round-2xl"
        >
          Start Building Your Career Story Now
        </Link>
        <p className="my-6 line-height-3 lg:text-right text-white text-2xl lg:pb-6">
          portfolii - Your Platform for Crafting Compelling Career Narratives.
        </p>
      </div>
    </div>
  );
};

export default Hero;
